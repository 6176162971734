import { Heading, TextLink } from '@troon/ui';
import { For } from 'solid-js';
import { Content } from '../../../../../components/main-content';
import type { RouteSectionProps } from '@solidjs/router';

export default function Resources(props: RouteSectionProps) {
	const faqs = [
		['Troon Rewards', 'troon-rewards-faqs'],
		['Troon Access', 'troon-access-faqs'],
		['Troon Operator', 'troon-operator-faqs'],
	];

	return (
		<Content>
			<Heading as="h1">Resources</Heading>

			<div class="flex flex-col gap-8 rounded border border-neutral bg-white p-4 xl:p-8">
				<Heading as="h2">FAQs</Heading>

				<For each={faqs}>
					{([title, slug]) => (
						<TextLink href={`/facility/${props.params.facility}/resources/faqs/${slug}`}>{title}</TextLink>
					)}
				</For>
			</div>
		</Content>
	);
}
